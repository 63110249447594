import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Downloads",
  "description": "",
  "image": ""
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "download-pdfepubmobi"
    }}>{`Download PDF/epub/mobi`}</h1>
    <div id="lock" />
    <p>{`Hi,`}</p>
    <p>{`The beauty of digital books is that you can have moving gifs, copy pastable code, clickable links, interactive features, responsive layouts, blazing fast load times, low battery usage, and offline support.`}</p>
    <p>{`Websites are good at that.`}</p>
    <p>{`But I get it. You're not into that. You like e-ink and 30meg PDF files. 🤷‍♀️`}</p>
    <p>{`Here you go:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://course-downloadables.s3.amazonaws.com/serverless-handbook/Serverless+Handbook.pdf"
        }}>{`download PDF`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://course-downloadables.s3.amazonaws.com/serverless-handbook/Serverless+Handbook.epub"
        }}>{`download epub`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://course-downloadables.s3.amazonaws.com/serverless-handbook/Serverless+Handbook.mobi"
        }}>{`download mobi`}</a></li>
    </ul>
    <p>{`Content should be the same as on this website as of Mar 25th, 2021. If something's different, pelase let me know.`}</p>
    <p>{`Cheers,`}<br />{`
~Swizec`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      